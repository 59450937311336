<template>
    <nav class="navbar navbar-light justify-content-between flex-column flex-md-row">
        <div :class="['d-flex justify-content-between', !$widerThan('md', $vuetify.breakpoint.name) && 'w-100']">
            <a class="navbar-brand d-flex flex-row align-items-center" :href="getPath">
                <v-img :src="logo"
                    class="rounded-lg"
                    contain
                    width="42"
                    height="42" />
                <div class="ml-2" id="navbrand-name"><strong>Tobias</strong> Informador</div>
            </a>
            <div v-if="!$widerThan('md', $vuetify.breakpoint.name) && showButtonsHome"
                 class="d-flex justify-content-center">
                <v-icon large 
                        color="black"
                        @click="open = !open"
                        style="height:fit-content;align-self:center">
                    {{open ? 'mdi-backburger' : 'mdi-menu'}}
                </v-icon>
            </div>
        </div>
        <div v-if="$route.name !== 'NotFound'" :class="!$widerThan('md', $vuetify.breakpoint.name) && 'w-100'">
            <div v-if="showButtonsLogin" class="d-lg-inline d-none">
                <ul class="navbar-nav d-flex flex-row">
                    <li class="nav-item d-flex align-items-center">
                        <v-btn id="btn-login"
                            class="nav-link btn-nav"
                            elevation="0"
                            to="/login">
                            Login
                        </v-btn>
                    </li>
                    <li class="nav-item ml-4">
                        <v-btn class="nav-link btn-nav"
                            id="btn-cadastrar"
                            elevation="0"
                            to="/cadastro">
                            Cadastrar
                        </v-btn>
                    </li>
                </ul>
            </div>
            <div v-else-if="showButtonsHome" :class="[getHomeClass,'w-100']" :id="getIdByPath">
                <ul class="navbar-nav d-flex flex-column flex-lg-row pl-0">
                    <li class="nav-item mr-lg-2 mr-0  nav-home">
                        <v-btn class="nav-link btn-nav"
                               elevation="0"
                               :tile="!$widerThan('lg', $vuetify.breakpoint.name)"
                               to="/informes">
                            <div class="d-flex align-items-center justify-content-center" id="btn-news">
                                <v-icon>mdi-newspaper</v-icon>
                                Informes
                            </div>
                        </v-btn>
                    </li>
                    <li><hr id="divider-nav" /></li>
                    <li class="nav-item mx-lg-2 mx-0 nav-home">
                        <v-btn class="nav-link btn-nav"
                               elevation="0"
                               :tile="!$widerThan('lg', $vuetify.breakpoint.name)"
                               to="/categorias">
                            <div class="d-flex align-items-center justify-content-center" id="btn-categories">
                                <v-icon>mdi-shape</v-icon>
                                Categorias
                            </div>
                        </v-btn>
                    </li>
                    <li><hr id="divider-nav" /></li>
                    <li class="nav-item ml-lg-2 ml-0 nav-home">
                        <v-btn class="nav-link btn-nav"
                               elevation="0"
                               :tile="!$widerThan('lg', $vuetify.breakpoint.name)"
                               to="/perfil">
                            <div class="d-flex align-items-center justify-content-center" id="btn-profile">
                                <v-icon>mdi-account</v-icon>
                                Perfil
                            </div>
                        </v-btn>
                    </li>
                    <li><hr id="divider-nav" /></li>
                    <li class="nav-item ml-lg-2 ml-0 nav-home">
                        <v-btn class="nav-link btn-nav"
                               elevation="0"
                               :tile="!$widerThan('lg', $vuetify.breakpoint.name)"
                               @click="logout">
                            <div class="d-flex align-items-center justify-content-center" id="btn-profile">
                                <v-icon>mdi-logout</v-icon>
                                Sair
                            </div>
                        </v-btn>
                    </li>
                </ul>
            </div>
            <div v-else-if="$route.name !== 'Selecionar'" class="d-lg-inline d-none">
                <ul class="navbar-nav d-flex flex-row">
                    <li class="nav-item ml-4">
                        <v-btn class="nav-link btn-nav"
                            id="btn-voltar-nav"
                            elevation="0"
                            to="/">
                            <v-icon class="mr-2">mdi-chevron-left</v-icon>
                            <p class="ma-0 mr-2">Voltar</p>
                        </v-btn>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import logo from "../assets/logo.png";
    import widerThan from "../utils/breakpoint";

    export default {
        name: "Navbar",

        data: () => ({
            logo,
            showLoginButtons: false,
            showHomeButtons: false,
            open: false,
        }),

        methods: {
            widerThan: function (name) {
                return widerThan(name, this.$vuetify.breakpoint.name);
            },

            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/')
                    })
            },
        },

        computed: {
            showButtonsLogin() {
                return this.$route.name === "SplashScreen";
            },
            
            showButtonsHome() {
                return this.$route.name !== "SplashScreen"
                    && this.$route.name !== "Login"
                    && this.$route.name !== "Selecionar"
                    && this.$route.name !== "Cadastro"
            },

            getHomeClass() {
                return 'mt-5 mt-md-0 ' + (this.open ? 'open-nav-home' : 'closed-nav-home')
            },

            getPath() {
                let name = this.$route.name;
                if (name !== "SplashScreen" && name !== "Login" && name !== "Selecionar" && name !== "Cadastro") {
                    return '/informes'
                } else {
                    return '/'
                }
            },

            getIdByPath() {
                if (this.$route.name === "Informes") return 'nav-home-news';
                if (this.$route.name === "Categorias") return 'nav-home-categories';
                if (this.$route.name === "Perfil") return 'nav-home-profile';
                return 'nav-home-default'
            }
        }
    };
</script>

<style>
    #divider-nav {
        margin: 0 2px;
        width: 2px;
        height: 100%;
        background: #D9D9D9;
    }

    #navbrand-name {
        font-size: 24px;
        line-height: 28px;
    }

    .nav-home a {
        padding-left: 10px !important;
    }

    .nav-home a span {
        display: inline;
    }

    .btn-nav {
        font-weight: 400 !important;
        min-width: 150px !important;
        letter-spacing: 0.04em !important;
        background: transparent !important;
    }

    .btn-nav i {
        margin-right: 8px;
    }

    #btn-cadastrar {
        color: #269D4F;
        border: 1px solid #269D4F;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px 15px;
    }

    #btn-voltar-nav {
        color: #222;
        border: 1px solid #222;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 20px 15px;
    }

    #btn-login {
        color: #000;
    }

    .v-btn--active {
        font-weight: 700 !important;
        background: transparent;
    }

    .v-btn.v-btn--active::before {
        background-color: transparent;
    }

    .v-btn--active #btn-news {
        color: #269D4F;
    }

    .v-btn--active #btn-categories {
        color: #2F80ED;
    }

    .v-btn--active #btn-profile {
        color: #5A57EB;
    }

    @media (max-width: 1263px) {
        .nav-home a span {
            display: flex;
        }
        #nav-home-news {
            border-left: 1px solid #269D4F;
        }

        #nav-home-categories {
            border-left: 1px solid #2F80ED;
        }

        #nav-home-profile {
            border-left: 1px solid #5A57EB;
        }

        #nav-home-default {
            border-left: 1px solid black;
        }
        .open-nav-home {
            display: flex;
            transition: 0.5s;
        }

        .closed-nav-home {
            display: none;
            transition: 0.5s;
        }

        nav .v-btn__content {
            justify-content: flex-start;
        }
    }

    @media (max-width: 960px) {
        ul, li, li a {
            width: 100%;
        }
    }

    @media (max-width: 350px) {
        .navbar-brand {
            white-space: inherit;
        }
    }
</style>