import Vue from 'vue'
import vuescroll from 'vuescroll/dist/vuescroll-slide';

import vuetify from './plugins/vuetify'
import router from './router'
import colors from './styles'
import widerThan from './utils/breakpoint';
import store from './store';
import axios from 'axios';

import App from './App.vue'

Vue.prototype.$http = axios;
const token = localStorage.getItem('token');
if (token) Vue.prototype.$http.defaults.headers.common['Authorization'] = token;

Vue.use(vuescroll);

Vue.config.productionTip = false
Vue.prototype.$colors = colors;
Vue.prototype.$widerThan = (name, target) => widerThan(name, target);
Vue.prototype.$vuescrollConfig = {
    bar: {
        background: '#269D4F'
    }
};

new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')
