import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

const NotFound = () => import('../views/NotFound.vue');
const SplashScreen = () => import('../views/SplashScreen.vue');
const Login = () => import('../views/Login.vue');
const Cadastro = () => import('../views/Cadastro.vue');
const Selecionar = () => import('../views/Selecionar.vue');
const Informes = () => import('../views/Informes.vue');
const Categorias = () => import('../views/Categorias.vue');
const Perfil = () => import('../views/Perfil.vue');

const routes = [
    {
        path: '/',
        name: 'SplashScreen',
        component: SplashScreen,
        meta: {
            guest: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true,
        }
    },
    {
        path: '/cadastro',
        name: 'Cadastro',
        component: Cadastro,
        meta: {
            guest: true,
        }
    },
    {
        path: '/selecionar',
        name: 'Selecionar',
        component: Selecionar,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/informes',
        name: 'Informes',
        component: Informes,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/categorias',
        name: 'Categorias',
        component: Categorias,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/perfil',
        name: 'Perfil',
        component: Perfil,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
]

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name === "NotFound") next();
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    
    const token = localStorage.getItem('token');
    const lsuser = localStorage.getItem('user');
    let user;
    console.log(lsuser);
    if (lsuser != null) {
        try {
            user = JSON.parse(lsuser);
        } catch (e) {
            localStorage.removeItem('user');
            user = null;
        }
    }
    let isVerified = false;

    if (requiresAuth) {
        if (!token || !user) {
            store.dispatch('logout')
            next('/login')
        }

        store.dispatch('verify', {token, user})
            .then((res) => {
                isVerified = res.data.isSuccessStatusCode;
                if (store.getters.isLoggedIn && isVerified) {
                    next();
                    return;
                } else {
                    store.dispatch('logout');
                    next('/login');
                    return;
                }
            })
            .catch(() => { isVerified = false;})


    }

    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isLoggedIn && isVerified) {
            next("/informes");
            return;
        } else {
            next();
        }
    }
})

export default router
