const colors = {
    primary: '#43BC6C',
    news: '#269D4F',
    categories: '#2F80ED',
    profile: '#5A57EB',

    grays: {
        white: '#FFFFFF',
        inputFill: '#F8F8F8',
        inputBorder: '#DDDDDD',
        disabled: '#AAAAAA',
        placeholder: '#888888',
        inputText: '#444444',
        black: '#000000'
    }
}

export default colors;