<template>
    <v-app>
        <v-main>
            <div id="container-app" class="container h-100 d-flex flex-column px-md-0 px-4">
                <header class="mt-5" v-if="!isEmailRoute">
                    <Navbar />
                </header>
                <router-view />
            </div>
        </v-main>
    </v-app>    
</template>

<script>
    import Navbar from "./components/Navbar.vue"

    export default {
        name: 'App',

        components: {
            Navbar,
        },

        data: () => ({}),

        computed: {
            isEmailRoute: function () {
                return this.$route.name === 'Email'
            }
        },

        /* eslint-disable */
        created: function () {
            this.$http.interceptors.response.use(undefined, function (err) {
                return new Promise(function (resolve, reject) {
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        this.$store.dispatch(logout)
                    }
                    throw err;
                });
            });
        }
        /* eslint-enable */
    }
</script>

<style>
    * {
        font-family: 'Roboto', sans-serif;
        margin: 0;
        padding: 0;
    }

    body {
        position: relative;
    }

    h1 {
        font-weight: bold;
        font-size: 60px;
        line-height: 70px;
    }

    h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
    }

    #container-app {
        width: 75%;
    }
    
    .avoid-click {
        pointer-events:none;
    }

    .text-form {
        max-width: 500px;
        align-self: center;
    }

    .paragraph-form {
        font-size: 18px;
    }

    .standard-form {
        width: 30%;
        min-width: 380px;
    }

    .container-form {
        display: flex;
        align-items: center;
    }

    @media (min-width: 2000px) {
        .container-form {
            display: block;
            margin-top: 60px;
        }
    }

    .divider {
        margin: 0 30px;
        min-width: 2px;
        height: 100%;
        max-height: 700px;
        align-self: center;
    }

    .btn {
        min-height: 50px !important;
        text-transform: none;
        font-weight: 500;
        font-size: 18px !important;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        white-space: normal;
    }

    @keyframes fadeInOpacity {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }

    @media (max-width: 960px) {
        h1 {
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
        }

        #container-app {
            width: 100%;
        }

        .container-form {
            margin: 0;
        }

        .paragraph-form {
            font-size: 16px;
        }

        .standard-form {
            min-width: 0;
            width: 100%;
        }

        .v-main {
            margin-bottom: 30px;
        }

        .divider {
            margin: 20px 0;
            width: 100%;
            height: 5px;
        }
    }
</style>