import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import '@mdi/font/css/materialdesignicons.css'
import colors from '../styles';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: colors.primary,
                news: colors.news,
                categories: colors.categories,
                profile: colors.profile,
                error: '#EB5757',
            },
        },
    },
});
