import axios from 'axios';

const state = {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {}
};

const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authUser: state => state.user,
    authToken: state => state.token,
};

const actions = {
    verify({ commit }, obj) {
        return new Promise((resolve, reject) => {
            const {token, user} = obj
            commit('auth_request')
            try {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                axios.get('/api/Auth/Verify?email=' + user.email)
                    .then(resp => {
                        commit('auth_success', token)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('logout')
                        localStorage.removeItem('token')
                        localStorage.removeItem('user')
                        delete axios.defaults.headers.common['Authorization']
                        reject(err)
                    })
            } catch (err) {
                console.log(err)
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                delete axios.defaults.headers.common['Authorization']
                reject(err)
            }
        })
    },

    login({ commit }, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/api/Auth/Login', user)
                .then(resp => {
                    if (resp.data.success) {
                        const token = resp.data.response.access_token;

                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(resp.data.user))
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

                        commit('auth_success', token)
                        commit('set_user', resp.data.user)
                    }
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    reject(err)
                })
        })
    },

    register({ commit }, user) {
        return new Promise((resolve, reject) => {
            let Email = user.Email;
            let Password = user.Password;
            let Name = user.Name;

            commit('auth_request')
            axios.post('/api/Auth/Register', { Email, Password, Name })
                .then(resp => {
                    if (resp.data.success) {
                        const token = resp.data.response.access_token;
                        const token_type = resp.data.token_type;

                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(resp.data.user))
                        axios.defaults.headers.common['Authorization'] = token_type + ' ' + token

                        commit('auth_success', token)
                        commit('set_user', resp.data.user)
                    }
                    resolve(resp)
                })
                .catch(err => {
                    console.log('nao foi')
                    commit('auth_error')
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    reject(err)
                })
        })
    },

    logout({ commit }) {
        /* eslint-disable */
        return new Promise((resolve, reject) => {
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
        /* eslint-enable */
    },

        /* eslint-disable */
    updateUser({ commit }, user) {
        console.log(user)
        return new Promise((resolve, reject) => {
            try {
                localStorage.setItem('user', JSON.stringify(user))
                commit('set_user', user)
                resolve()
            } catch (e) {
                console.log(e)
                reject()
            }
        })
        /* eslint-enable */
    }
};

const mutations = {
    auth_request(state) {
        state.status = 'loading';
    },
    auth_success(state, token) {
        state.status = 'success';
        state.token = token;
    },
    auth_error(state) {
        state.status = 'error';
    },
    set_user(state, user) {
        state.user = user;
    },
    logout(state) {
        state.status = '';
        state.token = '';
        state.user = {};
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};